<template>
  <div id="epg">
    <b-row class="mb-5">
      <b-col
        md="10"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('listEpg') }}</h1>
        <h5 class="text-primary">
          {{ $t('confEpg') }}
        </h5>
      </b-col>
      <b-col
        v-if="!vacio"
        style="text-align: end;"
      >
        <b-button
          variant="success"
          @click="$refs['my-modal'].show()"
        >
          <span>{{ $t('createChannel') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-row v-if="vacio">
        <b-col cols="12">
          <b-jumbotron
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('createChannel')"
            class="text-center"
          >
            <p class="text-primary">
              {{ $t('noExist') }}
            </p>

            <b-col
              class="text-center m-10 p-5"
              align-self="center"
            >
              <b-button
                variant="success"
                @click="$refs['my-modal'].show()"
              >
                <span>{{ $t('dataGeneric.create') }}</span>
              </b-button>
            </b-col>
          </b-jumbotron>
        </b-col>
      </b-row>
      <b-overlay
        v-else
        variant="transparent"
        :show="showEpg"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row>
          <b-col
            v-for="channel in epgChannel"
            :key="channel.node.id"
            class="mt-5 cursor-pointer"
            md="6"
            lg="3"
            xl="4"
            sm="12"
          >
            <b-card
              class="carCat"
              no-body
            >
              <b-img
                :src="buildImageUrl(channel.node.content.imageUrl)"
                class="card-img-top cursor-pointer"
                img-alt="Card image cap"
                @error="errorImg"
                @click="routeToView(channel.node.id)"
              />
              <b-card-header>
                <div>
                  <b-card-title class="mb-25">
                    <span
                      class="mr-50 bullet bullet-success bullet-sm"
                      :class="{
                        'bullet-success': channel.node.isActive,
                        'bullet-danger': !channel.node.isActive
                      }"
                    />EPG {{ channel.node.content.name }}
                  </b-card-title>
                  <b-card-text class="mb-0" />
                </div>
              </b-card-header>

              <b-card-body>
                <div class="actions">
                  <div class="action">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          color="white"
                          icon="MoreVerticalIcon"
                          size="20"
                        />
                      </template>
                      <b-dropdown-item @click="routeToView(channel.node.id)">
                        <feather-icon
                          icon="Edit2Icon"
                          size="18"
                        />
                        {{ $t('dataGeneric.edit') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteChannel(channel.node.id)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t('dataGeneric.delete') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('contents.selectContent')"
    >
      <div class="d-block text-center">
        <content-selector
          :type-content="'DIR'"
          @data="SelectContentId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['my-modal'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BImg,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BJumbotron,
  BOverlay,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, utf8ToB64, showToast } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ContentSelector from '@/views/common/ContentSelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCardHeader,
    BJumbotron,
    BCardBody,
    BCardTitle,
    BCardText,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    ContentSelector,
    BButton,
    BCard,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      vacio: true,
      epgChannel: [],
      showEpg: false,
      idEpg: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.showEpg = true
      const userData = getUserData()
      axios
        .post('', {
          query: `
            {
                allEpg(client:"${userData.profile.client.id}") {
                edges {
                    node {
                    id
                    isActive
                    content {
                        id
                        name
                        imageUrl
                    }
                    epgEpgContent {
                        edges {
                        node {
                            id
                            name
                            description
                            startDate
                            duration
                            
                        }
                        }
                    }
                    }
                }
            }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.epgChannel = result.data.data.allEpg.edges
          this.vacio = this.epgChannel.length === 0
          this.showEpg = false
        })
        .catch(() => { })
    },
    deleteChannel(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios
              .post('', {
                query: `
           mutation{
                deleteEpg(id:"${id}") {
                    found
                    deletedId
                }
            }
        `,
              })
              .then(res => {
                messageError(res, this)

                showToast(this.$t('success'), 1, this)
                this.getData()
              })
              .catch(() => {
                showToast(this.$t('error'), 0, this)
              })
          }
        })
        .catch(() => { })
    },
    routeToView(id) {
      this.$router.push({ name: 'contents-epg-contents', params: { id } })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    SelectContentId(data) {
      this.idEpg = data
      this.createEpg()
      this.$refs['my-modal'].hide()
    },

    createEpg() {
      const userData = getUserData()

      axios
        .post('', {
          query: `
        mutation{
            createEpg(input:{
                client:"${userData.profile.client.id}",
              content:"${utf8ToB64(`id:${this.idEpg}`)}"}){
              epg {
                    id
                    isActive
                    content {
                        id
                        name
                        description
                        imageUrl
                    }
                    }
                }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.getData()
          this.$refs['my-modal'].hide()
        })
        .catch(err => {
          console.log(err)
          this.$refs['my-modal'].hide()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#epg .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#epg .card-body h4 {
  font-size: 1.286rem !important;
}

#epg .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#epg .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#epg .card img {
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#epg .card-body {
  padding: 0rem;
}

#epg .card-body h4 {
  font-size: 1rem !important;
}

#epg .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#epg .carCat img {
  height: 15vw;
  object-fit: cover;
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}
</style>
